
<template>
    <v-app>
        <Menu/>
        <v-parallax :src="require('./img/burguer.jpg')" style="text-align: center; height: 550px">
            <div style="height: 100%; background: rgba(0,0,0,.7); margin: 0 -16px 0 -16px;">
                <div style="margin-top: 220px;">
                    <div :style="`display: flex; font-size: ${$vuetify.breakpoint.smAndUp ? '55px' : '25px'}`">
                        <div style="width: 50%; margin-right: 15px; text-align: right">Procurando</div>
                        <b><textra :data="words" :timer="2" :infinite='true' filter="simple"/></b>
                    </div>
                    <div class="pa-4">
                        Os melhores cardápios estão aqui. Descubra!
                    </div>
                    <div>
                        <input
                            type="text"
                            class="search"
                            v-model="cep"
                            v-mask="['#####-###']"
                            placeholder="Digite seu CEP"
                            @keypress.enter="pesquisar()"
                        >
                    </div>
                </div>
            </div>
            <!-- <div id="count" class="hidden-xs">
                <ul>
                    <li><span class="number">3</span> Estabelecimentos</li>
                    <li><span class="number">62</span> Produtos cadastrados</li>
                    <li><span class="number">544</span> Pedidos Realizados</li>
                </ul>
            </div> -->
        </v-parallax>

        <div class="container">
            <div class="main_title">
                <br>
                <h2 class="nomargin_top" style="padding-top:0">Como funciona</h2>
                <p>Veja como é simples fazer seu pedido.</p>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="box_home" id="one">
                        <span>1</span>
                        <h3>Pesquisa por endereço</h3>
                        <p>Encontre todos os restaurantes disponíveis em sua região.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box_home" id="two">
                        <span>2</span>
                        <h3>Escolha um restaurante</h3>
                        <p>Temos os melhores cardápios disponíveis aqui.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box_home" id="three">
                        <span>3</span>
                        <h3>Forma de pagamento</h3>
                        <p>Informe os dados entrega ou retirada e insira suas observações!</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="box_home" id="four">
                        <span>4</span>
                        <h3>E por fim, Delivery</h3>
                        <p>Envie seu pedido para o restaurante via whatsapp</p>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 70px;">
            <div>
                <img
                    contain
                    aspect-ratio
                    :height="$vuetify.breakpoint.smAndUp ? '550' : '320'"
                    :width="$vuetify.breakpoint.smAndUp ? '50%' : '100%'"
                    :src="require('./img/slides/2.jpg')"
                    alt="Bateu a fome?"
                >
                <div class="col-md-6 nopadding">
                    <div class="features-content" :style="$vuetify.breakpoint.smAndUp ? '' : 'height: 420px;'">
                        <h3>Bateu a Fome?</h3>
                        <ul class="list_ok">
                            <li> Pizzarias</li>
                            <li> Restaurantes</li>
                            <li> Lanchonetes</li>
                            <li> Hambúrguerias</li>
                            <li> Sorveterias</li>
                            <li> Bares</li>
                            <li> Food Trucks, etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="high_light">
            <div class="container">
                <h3>Dono de <strong>restaurante</strong> ?</h3>
                <p>Crie agora seu cardápio online e comece a divulgar hoje mesmo.</p>
                <a style="cursor: pointer;" @click="sendWhatsapp">CADASTRAR RESTAURANTE</a>
            </div>
        </div>
        <div class="container">
            <div class="main_title">
                <br>
                <br>
                <h2 class="nomargin_top">Somos a melhor plataforma de pedidos<br>online integrada com
                    <strong>WhatsApp.</strong>
                </h2>
                <br>
                <p>Tenha seu próprio canal de vendas e tenha um maior relacionamento com seus clientes, sem intermediários.
                </p>
            </div>
            <div class="row">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.3s">
                    <div class="feature">
                        <i class="icon_mobile"></i>
                        <h3><span>Suporte</span> em Smartphones</h3>
                        <p>Nosso cardápio digital roda em qualquer dispositivo.</p>
                    </div>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.4s">
                    <div class="feature">
                        <i class=" icon-link"></i>
                        <h3><span>Link</span> amigavel</h3>
                        <p>Link amigavel para você divulgar e enviar para seus clientes.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="feature">
                        <i class="icon-volume-up"></i>
                        <h3><span>Notificação </span>de pedidos</h3>
                        <p>Através do painel o sistema notifica sobre um novo pedido.</p>
                    </div>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.6s">
                    <div class="feature">
                        <i class="icon-chart-outline"></i>
                        <h3><span>Estatísticas</span> do seu negócio</h3>
                        <p>Gráficos sobre seus pedidos em um ambiente agradável!</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                    <div class="feature">
                        <i class="icon-ticket-1"></i>
                        <h3><span>Cupons </span>de desconto</h3>
                        <p>Use cupons de descontos para fidelizar clientes.</p>
                    </div>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.8s">
                    <div class="feature">
                        <i class="icon-print-2"></i>
                        <h3><span>Impressão</span> de Pedidos.</h3>
                        <p>Impressão automática dos pedidos para agilizar na cozinha.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 wow fadeIn" data-wow-delay="0.9s">
                    <div class="feature">
                        <i class="icon-money-1"></i>
                        <h3><span>Fácil </span>de usar.</h3>
                        <p>Não perca tempo, cadastre os produtos e comece a usar hoje. </p>
                    </div>
                </div>
                <div class="col-md-6 wow fadeIn" data-wow-delay="1.0s">
                    <div class="feature">
                        <i class="icon-arrows-cw-2"></i>
                        <h3><span>Atualizações</span> constantes.</h3>
                        <p>Estamos sempre evoluindo e melhorando ainda mais o sistema.</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="planos" class="white_bg">
            <div class="container">
                <div class="main_title margin_mobile">
                    <h2 class="nomargin_top">Nosso Plano</h2>
                    <p>Teste grátis sem compromisso!</p>
                </div>
                <div class="row text-center plans">
                    <div class="plan col-md-4" v-show="$vuetify.breakpoint.smAndUp"/>
                    <div class="plan plan-tall col-md-4">
                        <span class="ribbon"></span>
                        <span class="ribbon_table"></span>
                        <h2 class="plan-title">PLANO MENSAL</h2>
                        <!-- <p class="plan-price">R$119<span>/90</span></p> -->
                        <ul class="plan-features">
                            <li>Adaptável ao seu negócio</li>
                            <li>Cardápio Digital / <strong>zaper MENU</strong></li>
                            <li>Módulo de Comandas / <strong>zaper Comandas</strong></li>
                            <li>Cardápio com visual Netflix</li>
                            <li>Pedidos <strong>Ilimitados</strong></li>
                            <li>Produtos <strong>Ilimitados</strong></li>
                            <li><strong>Impressão automática</strong> de pedidos</li>
                            <li>Cupom de Desconto</li>
                            <li>Cobrança de Taxa de Entrega por Bairro ou Distância</li>
                            <li><strong>Controle de Permissões</strong> dos Usúarios</li>
                            <li>Relatório de Vendas</li>
                            <li><strong>Suporte Premium</strong></li>
                            <li>Sem Taxas ou Comissões</li>
                            <li>Cancele Quando Quiser</li>
                        </ul>
                        <v-btn class="ma-2" outlined color="red" large @click="sendWhatsapp">
                            SABER MAIS
                        </v-btn>
                    </div>
                    <div class="plan col-md-4" v-show="$vuetify.breakpoint.smAndUp"/>
                </div>
            </div>
        </div>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div id="social_footer">
                            <ul>
                                <!-- <li>
                                    <a target="_blank" href="https://www.facebook.com/zapper.menu">
                                        <i class="icon-facebook"></i>
                                    </a>
                                </li> -->
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/zapermenu">
                                        <i class="icon-instagram"></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a target="_blank" href="https://www.instagram.com/zapper.menu">
                                        <i class="icon-youtube-play"></i>
                                    </a>
                                </li> -->
                            </ul>
                            <p>©  {{ new Date().getFullYear() }} | zapermenu</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <v-dialog v-model="dialog" width="600px">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <span style="font-size: 16px;">Próximos a você</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div v-if="loading" class="loading">Aguarde, carregando...</div>
                    <v-card
                        v-else-if="empresas.length"
                        v-for="(e, index) in empresas"
                        :key="index"
                        class="mx-auto ma-2 linker"
                        max-width="344"
                        outlined
                        elevation="5"
                        @click="open(e.dominio)"
                    >
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="card-title">
                                    {{ e.fantasia }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="card-subtitle">
                                    {{ e.municipio }} - {{ e.uf }}
                                </v-list-item-subtitle>
                                <v-btn
                                    color="#e50914"
                                    class="white--text"
                                >Acessar</v-btn>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="80">
                                <v-img
                                    max-height="150"
                                    max-width="250"
                                    :src="getLogo(e.logo)"
                                ></v-img>
                            </v-list-item-avatar>
                        </v-list-item>
                    </v-card>
                    <div v-else style="text-align: center; font-size: 14px; margin: 30px 0 30px 0;">
                        Não encontramos nenhum estabelecimento para o cep informado!
                        <v-btn color="#e50914" class="white--text mt-6" @click="indicar">
                            <v-icon class="mr-1">mdi-hand-coin-outline</v-icon>
                            Indicar estabelecimento
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div>
            <img
                class="whatsapp-btn"
                :src="require('@/assets/whatsapp-btn.png')"
                alt="Preciso de Ajuda"
                @click="sendWhatsapp"
            >
        </div>
    </v-app>
</template>

<script>
import Menu from './Menu'
import Textra from './Textra'

export default {
    name: 'Home',

    components: { Menu, Textra },

    data: () => ({
        cep: '',
        words: ['Restaurantes?', 'Lanchonetes?', 'Hambúrguerias?', 'Pizzarias?', 'Sorveterias?'],
        dialog: false,
        loading: false,
        empresas: [],
    }),

    methods: {
        sendWhatsapp() {
            const msg = 'Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20ZaperMenu';
            window.open(`https://api.whatsapp.com/send?phone=5549988951210&text=${msg}`);
        },

        // cadastrar() {
        //     window.open('https://admin.zapermenu.com.br/register', '_blank');
        // },

        pesquisar() {
            if (!this.cep) {
                return;
            }
            this.dialog = true;
            this.loading = true
            this.$http.get('empresa/get-by-cep/' + this.cep).then(resp => {
                this.empresas = resp.data.data;
            }).finally(() => (this.loading = false));
        },

        indicar() {
            const msg = 'Ol%C3%A1%2C%20gostaria%20de%20indicar%20um%20estabelecimento%20para%20o%20ZaperMenu%20e%20receber%20comiss%C3%A3o!';
            window.open(`https://api.whatsapp.com/send?phone=5549988951210&text=${msg}`);
        },

        open(route) {
            window.open(`https://app.zapermenu.com.br/${route}`);
        },

        getLogo(img) {
            if (!img || img[0]?.url) {
                return require('@/assets/noimageavailable.png');
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        notify(text, type = 'success') {
            setTimeout(() => this.$root.$emit('notify', {text, type}), 100);
        }
    },
}
</script>

<style scoped>
@import './css/base.css';

.v-application a {
    color: red !important;
}

.v-application a:hover {
    color: white !important;
}

.search {
    width: 60%;
    height: 50px;
    background-color: white;
    text-align: center;
    color: #363636;
    font-size: 18px;
    border-radius: 25px;
    outline: none;
}

.whatsapp-btn {
    position: fixed;
    z-index: 100;
    right: 15px;
    bottom: 15px;
    height: 80px;
    cursor: pointer;
}

.linker {
    cursor: pointer;
}

.loading {
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin: 80px 0 80px 0;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
}

.card-subtitle {
    font-size: 14px;
    color: rgb(87, 87, 87);
}
</style>
